import {gql} from "@apollo/client";

export const FormResponseFragment = gql`
	fragment FormResponseFragment on Form {
		error
		success
	}
`

export const ContactForm = gql`
	${FormResponseFragment}
	mutation ($name: String!, $phone: String, $recaptcha: String, $email: String!, $subject: String!, $message: String!, ) {
		ContactForm (name: $name, phone: $phone, recaptcha: $recaptcha, email: $email, subject: $subject, message: $message, ) {
			...FormResponseFragment
		}
	}
`

export const AppointmentForm = gql`
	${FormResponseFragment}
	mutation ($name: String!, $phone: String!, $email: String!, $message: String, $category: String, ) {
		AppointmentForm (name: $name, phone: $phone, email: $email, message: $message, category: $category, ) {
			...FormResponseFragment
		}
	}
`

