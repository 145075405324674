import {gql} from "@apollo/client";
import {CategoryFragment} from "./Category";
import {CategoryFragmentAdmin} from "./Category";

export const SimptomFragment = gql`
	fragment SimptomFragment on Simptom {
		id
		position
		name
		code
		active
	}
`

export const GetSimptom = gql`
	${SimptomFragment}
	${CategoryFragment}
	query ($id: ID!, ) {
		GetSimptom (id: $id, ) {
			...SimptomFragment
			categories			{
				...CategoryFragment
			}
		}
	}
`

export const GetSimptoms = gql`
	${SimptomFragment}
	${CategoryFragment}
	query ($search: String, $active: Boolean, $page: Int, $first: Int, $limit: Int, $category_id: [ID], ) {
		GetSimptoms (search: $search, active: $active, page: $page, first: $first, limit: $limit, category_id: $category_id, ) {
			...SimptomFragment
			categories			{
				...CategoryFragment
			}
		}
	}
`

export const GetSimptomsAdmin = gql`
	${SimptomFragment}
	${CategoryFragmentAdmin}
	query ($page: Int, $first: Int, $search: String, ) {
		GetSimptomsAdmin (page: $page, first: $first, search: $search, ) {
			...SimptomFragment
			categories			{
				...CategoryFragmentAdmin
			}
			design_blocks_count

		}
	}
`

export const CreateSimptom = gql`
	${SimptomFragment}
	mutation ($name: String!, $position: Int, $code: String, ) {
		CreateSimptom (name: $name, position: $position, code: $code, ) {
			...SimptomFragment
		}
	}
`

export const UpdateSimptom = gql`
	${SimptomFragment}
	mutation ($id: ID!, $name: String, $active: Boolean, $content: String, $attach_category: ID, $detach_category: ID, $position: Int, ) {
		UpdateSimptom (id: $id, name: $name, active: $active, content: $content, attach_category: $attach_category, detach_category: $detach_category, position: $position, ) {
			...SimptomFragment
		}
	}
`

export const DeleteSimptom = gql`
	${SimptomFragment}
	mutation ($id: ID!, ) {
		DeleteSimptom (id: $id, ) {
			...SimptomFragment
		}
	}
`

