import {gql} from "@apollo/client";
import {StorageFragment} from "./Storage";
import {CategoryFragment} from "./Category";
import {CategoryFragmentAdmin} from "./Category";

export const ProductFragment = gql`
	fragment ProductFragment on Product {
		id
		code
		barcode
		active
		state
		name
		action
		new
		popular
		description
		long_description
		video
		seo_title
		seo_keywords
		seo_description
	}
`

export const ProductFragmentAdmin = gql`
	fragment ProductFragmentAdmin on Product {
		id
		code
		barcode
		active
		state
		name
		names
		action
		new
		popular
		description
		descriptions
		long_description
		long_descriptions
		video
		seo_title
		seo_titles
		seo_keywords
		seo_keywords_all
		seo_description
		seo_descriptions
	}
`

export const GetProducts = gql`
	${ProductFragment}
	${StorageFragment}
	${CategoryFragment}
	query ($search: String, $page: Int, $first: Int, $ids: [ID], $orderByIds: [ID], $active: Boolean, $category_id: ID, ) {
		GetProducts (search: $search, page: $page, first: $first, ids: $ids, orderByIds: $orderByIds, active: $active, category_id: $category_id, ) {
			data {
			...ProductFragment
			image			{
				...StorageFragment
			}
			categories			{
				...CategoryFragment
			}
			}
			paginatorInfo {
				currentPage
				lastPage
				count
				total
			}
		}
	}
`

export const GetProductsAdmin = gql`
	${ProductFragmentAdmin}
	${StorageFragment}
	${CategoryFragmentAdmin}
	query ($page: Int, $first: Int, $search: String, $state: Boolean, $active: Boolean, $category_id: ID, ) {
		GetProductsAdmin (page: $page, first: $first, search: $search, state: $state, active: $active, category_id: $category_id, ) {
			data {
			...ProductFragmentAdmin
			image			{
				...StorageFragment
			}
			categories			{
				...CategoryFragmentAdmin
			}
			}
			paginatorInfo {
				currentPage
				lastPage
				count
				total
			}
		}
	}
`

export const GetProduct = gql`
	${ProductFragment}
	${StorageFragment}
	${CategoryFragment}
	query ($id: ID!, ) {
		GetProduct (id: $id, ) {
			...ProductFragment
			image			{
				...StorageFragment
			}
			categories			{
				...CategoryFragment
			}
		}
	}
`

export const GetProductAdmin = gql`
	${ProductFragmentAdmin}
	${StorageFragment}
	${CategoryFragmentAdmin}
	query ($id: ID!, ) {
		GetProductAdmin (id: $id, ) {
			...ProductFragmentAdmin
			image			{
				...StorageFragment
			}
			categories			{
				...CategoryFragmentAdmin
			}
		}
	}
`

export const CreateProduct = gql`
	${ProductFragmentAdmin}
	mutation ($name: String!, $position: Int, ) {
		CreateProduct (name: $name, position: $position, ) {
			...ProductFragmentAdmin
		}
	}
`

export const UpdateProduct = gql`
	${ProductFragmentAdmin}
	mutation ($id: ID!, $name: String, $description: String, $active: Boolean, $state: Boolean, $image_id: ID, $position: Int, $seo_title: String, $seo_keywords: String, $seo_description: String, ) {
		UpdateProduct (id: $id, name: $name, description: $description, active: $active, state: $state, image_id: $image_id, position: $position, seo_title: $seo_title, seo_keywords: $seo_keywords, seo_description: $seo_description, ) {
			...ProductFragmentAdmin
		}
	}
`

export const DeleteProduct = gql`
	${ProductFragmentAdmin}
	mutation ($id: ID!, ) {
		DeleteProduct (id: $id, ) {
			...ProductFragmentAdmin
		}
	}
`

