import {gql} from "@apollo/client";

export const CategoryFAQFragment = gql`
	fragment CategoryFAQFragment on CategoryFAQ {
		id
		position
		question
		response
	}
`

export const GetCategoryFAQ = gql`
	${CategoryFAQFragment}
	query ($category_id: ID!, ) {
		GetCategoryFAQ (category_id: $category_id, ) {
			...CategoryFAQFragment
		}
	}
`

export const CreateCategoryFAQ = gql`
	${CategoryFAQFragment}
	mutation ($category_id: ID, $position: Int, ) {
		CreateCategoryFAQ (category_id: $category_id, position: $position, ) {
			...CategoryFAQFragment
		}
	}
`

export const UpdateCategoryFAQ = gql`
	${CategoryFAQFragment}
	mutation ($id: ID!, $position: Int, $question: String, $response: String, ) {
		UpdateCategoryFAQ (id: $id, position: $position, question: $question, response: $response, ) {
			...CategoryFAQFragment
		}
	}
`

export const DeleteCategoryFAQ = gql`
	${CategoryFAQFragment}
	mutation ($id: ID!, ) {
		DeleteCategoryFAQ (id: $id, ) {
			...CategoryFAQFragment
		}
	}
`

