
const APP_URL = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
            ? "http://localhost:3000"
            : window.location.origin;

function server(path) {
    if (path.startsWith("/")) path = path.substring(1);
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        return `http://localhost:5000/${path}`;
    }
    return `${window.location.origin}/${path}`;
}

const config =  {
    modules: {
        _app: {
            color: '#1d8691',
            server: server,
            appUrl: APP_URL,
            graphQL: server("graphql"),
            underRoot: true
        },
        lang: {
            languages: [
                {id: 0, short: 'sr', value: "/", name: "Srpski", label: "RS"},
                //{id: 1, short: 'en', value: "/home", name: "English", label: "EN"}
            ]
        },
        alerts: {},
        apollo: {},
        cms: {
            tokenName: "admntokjwt"
        },
        settings: {
            image: true
        },
        pages: {
            menus: [
                {id: 1, name: "Public"},
                {id: 2, name: "FAQ"},
                {id: 3, name: "Layouts"},
                {id: 4, name: "Protected"},
                {id: 100, name: "CMS"},
                {id: 101, name: "SEO"},
            ]
        },
        storage: {
            root: "storage",
            folder: "parkpol",
            maxUpload: 8000000,
            server: (path) => {
                if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                    return `https://parkpoliklinika.rs/${path}`;
                }
                return server(path)
            },
        },
        public: {},
        categories: {
            image: true,
            maxDepth: 2,
        },
        banners: {
            placement: {
                "categoriesmenu": {
                    name: "usluge strana",
                    description: "usluge strana desc",
                },
                "symptomright": {
                    name: "simptom strana",
                    description: "simptom strana desc"
                },
            }

        },
        design: {
            settings: {
                image: true
            },
            cards: {
                image: true
            },
            blocks: {
                banners: {
                    type: "banners",
                    name: "baneri",
                    cmsElement: "banners.CmsDesignBlock",
                    element: "banners.DesignBlock",
                },
                editor: {
                    type: "editor",
                    name: "text editor",
                    cmsElement: "texteditor.CmsDesignBlock",
                    element: "texteditor.DesignBlock",
                },
                categories: {
                    type: "categories",
                    name: "kategorije u carouselu",
                    cmsElement: "categories.CmsDesignBlock",
                    element: "categories.DesignBlock",
                },
                blogs: {
                    type: "blogs",
                    name: "poslednje vesti",
                    cmsElement: "blogs.CmsDesignBlock",
                    element: "blogs.DesignBlock",
                },
                cards: {
                    type: "cards",
                    name: "naslov, text i slika",
                    cmsElement: "design.CmsDesignBlockCards",
                    element: "design.DesignBlockCards",
                },
                info: {
                    type: "info",
                    name: "3 info bloka",
                    cmsElement: "design.CmsDesignBlockInfo",
                    element: "design.DesignBlockInfo",
                },
                title_desc_link: {
                    type: "title_desc_link",
                    name: "naslov opis i link dugme",
                    cmsElement: "design.CmsDesignBlockTDL",
                    element: "design.DesignBlockTDL",
                },
                progress: {
                    type: "progress",
                    name: "blok progres",
                    cmsElement: "design.CmsDesignBlockProgress",
                    element: "design.DesignBlockProgress",
                },
            },
        },
        nestable: {},
        seo: {},
        products: {},
        texteditor: {},
        blogs: {},
        pricelist: {},
        simptoms: {}
    },
}
export default config;