
const translations = {

    // CATEGORIES
    "kategoriju": {"sr": "uslugu", "en": ""},
    "kategorija": {"sr": "usluga", "en": ""},
    "kategorije": {"sr": "usluge", "en": ""},

    "3 info bloka": {"sr": "3 info bloka", "en": ""},
    "naslov opis i link dugme": {"sr": "Naslov, opis i link dugme", "en": ""},
    "blok progres": {"sr": "Blok progres", "en": ""},

    "usluge strana": {"sr": "Usluge strana", "en": "Shop page"},
    "usluge strana desc": {"sr": "Baneri ispod menija", "en": "Banners under the menu"},
    "simptom strana": {"sr": "Simptom strana", "en": "Product page"},
    "simptom strana desc": {"sr": "Desni baneri na simptom strani", "en": "Banners on the right"},
}
export default translations;