import {LoginAdministrator} from "./Admin";
import {CreateAdministrator} from "./Admin";
import {UpdateAdministrator} from "./Admin";
import {DeleteAdministrator} from "./Admin";
import {CreateAdminResetToken} from "./AdminReset";
import {ResetAdminPassword} from "./AdminReset";
import {UpdateSetting} from "./Setting";
import {CreatePage} from "./Page";
import {UpdatePage} from "./Page";
import {DeletePage} from "./Page";
import {CreatePageError} from "./PageError";
import {TransferStorageFolder} from "./Storage";
import {UploadStorageAdmin} from "./Storage";
import {DeleteStorage} from "./Storage";
import {CreateStorageFolder} from "./StorageFolder";
import {DeleteStorageFolder} from "./StorageFolder";
import {ContactForm} from "./Form";
import {AppointmentForm} from "./Form";
import {CreateCategory} from "./Category";
import {UpdateCategory} from "./Category";
import {DeleteCategory} from "./Category";
import {CreateCategoryFAQ} from "./CategoryFAQ";
import {UpdateCategoryFAQ} from "./CategoryFAQ";
import {DeleteCategoryFAQ} from "./CategoryFAQ";
import {DeleteDesignBlockCategory} from "./DesignBlockCategory";
import {CreateBanner} from "./Banner";
import {UpdateBanner} from "./Banner";
import {DeleteBanner} from "./Banner";
import {DeleteDesignBlockBanner} from "./DesignBlockBanner";
import {CreateDesignBlockCard} from "./DesignBlockCard";
import {UpdateDesignBlockCard} from "./DesignBlockCard";
import {DeleteDesignBlockCard} from "./DesignBlockCard";
import {CreateDesignBlock} from "./DesignBlock";
import {UpdateDesignBlock} from "./DesignBlock";
import {DeleteDesignBlock} from "./DesignBlock";
import {UpsertDesignBlockSetting} from "./DesignBlockSetting";
import {OrderNestable} from "./Nestable";
import {DeleteDesignBlockProduct} from "./DesignBlockProduct";
import {CreateProduct} from "./Product";
import {UpdateProduct} from "./Product";
import {DeleteProduct} from "./Product";
import {UpsertDesignBlockText} from "./DesignBlockText";
import {DeleteDesignBlockText} from "./DesignBlockText";
import {CreateBlog} from "./Blog";
import {UpdateBlog} from "./Blog";
import {DeleteBlog} from "./Blog";
import {CreatePricelist} from "./Pricelist";
import {UpdatePricelist} from "./Pricelist";
import {DeletePricelist} from "./Pricelist";
import {CreateSimptom} from "./Simptom";
import {UpdateSimptom} from "./Simptom";
import {DeleteSimptom} from "./Simptom";


const mutations = {
  "LoginAdministrator": {
    "mutation": LoginAdministrator,
    "rules": {
      "email": [
        "email",
        "max:255",
        "required"
      ],
      "password": [
        "min:6",
        "max:190",
        "required"
      ]
    }
  },
  "CreateAdministrator": {
    "mutation": CreateAdministrator,
    "rules": {
      "name": [
        "nullable",
        "max:190"
      ],
      "lastname": [
        "nullable",
        "max:190"
      ],
      "username": [
        "nullable",
        "max:190",
        "unique:admins"
      ],
      "email": [
        "email",
        "max:255",
        "required",
        "unique:admins"
      ],
      "password": [
        "min:6",
        "max:190",
        "required"
      ]
    }
  },
  "UpdateAdministrator": {
    "mutation": UpdateAdministrator,
    "rules": {
      "id": [
        "required"
      ],
      "email": [
        "email",
        "max:255",
        "min:5",
        "unique:admins"
      ],
      "name": [
        "nullable",
        "max:190"
      ],
      "lastname": [
        "nullable",
        "max:190"
      ],
      "username": [
        "nullable",
        "max:190",
        "unique:admins"
      ]
    }
  },
  "DeleteAdministrator": {
    "mutation": DeleteAdministrator,
    "rules": {
      "id": [
        "required"
      ]
    }
  },
  "CreateAdminResetToken": {
    "mutation": CreateAdminResetToken,
    "rules": {
      "email": [
        "email",
        "max:255",
        "required",
        "exists:admins,email"
      ]
    }
  },
  "ResetAdminPassword": {
    "mutation": ResetAdminPassword,
    "rules": {
      "email": [
        "email",
        "max:255",
        "required"
      ],
      "password": [
        "min:6",
        "max:190",
        "required"
      ],
      "token": [
        "min:16",
        "max:255",
        "required"
      ]
    }
  },
  "UpdateSetting": {
    "mutation": UpdateSetting,
    "rules": {
      "id": [
        "required"
      ],
      "value": [
        "nullable"
      ],
      "image_id": [
        "nullable",
        "exists:storage,id"
      ]
    }
  },
  "CreatePage": {
    "mutation": CreatePage,
    "rules": {
      "name": [
        "max:191",
        "required"
      ],
      "slug": [
        "max:191",
        "slugify:name",
        "required"
      ],
      "menu": [
        "integer",
        "nullable"
      ]
    }
  },
  "UpdatePage": {
    "mutation": UpdatePage,
    "rules": {
      "id": [
        "required"
      ],
      "name": [
        "max:191"
      ],
      "slug": [
        "max:191",
        "slugify:name"
      ],
      "active": [
        "nullable"
      ],
      "seo_title": [
        "nullable"
      ],
      "seo_keywords": [
        "nullable"
      ],
      "seo_description": [
        "nullable"
      ]
    }
  },
  "DeletePage": {
    "mutation": DeletePage,
    "rules": {
      "id": [
        "required"
      ]
    }
  },
  "CreatePageError": {
    "mutation": CreatePageError,
    "rules": {
      "content": [
        "required"
      ]
    }
  },
  "TransferStorageFolder": {
    "mutation": TransferStorageFolder,
    "rules": {
      "folder_id": [
        "nullable"
      ]
    }
  },
  "UploadStorageAdmin": {
    "mutation": UploadStorageAdmin,
    "rules": {
      "file": [
        "required"
      ],
      "name": [
        "max:500"
      ],
      "folder_id": [
        "exists:storage_folders,id",
        "nullable"
      ]
    }
  },
  "DeleteStorage": {
    "mutation": DeleteStorage,
    "rules": {
      "ids": [
        "required"
      ]
    }
  },
  "CreateStorageFolder": {
    "mutation": CreateStorageFolder,
    "rules": {
      "name": [
        "max:190",
        "required"
      ]
    }
  },
  "DeleteStorageFolder": {
    "mutation": DeleteStorageFolder,
    "rules": {
      "id": [
        "required"
      ]
    }
  },
  "ContactForm": {
    "mutation": ContactForm,
    "rules": {
      "name": [
        "required",
        "max:300"
      ],
      "phone": [
        "max:32"
      ],
      "recaptcha": [
        "max:1000"
      ],
      "email": [
        "required",
        "email",
        "max:300"
      ],
      "subject": [
        "required",
        "max:1000"
      ],
      "message": [
        "required",
        "max:2000"
      ]
    }
  },
  "AppointmentForm": {
    "mutation": AppointmentForm,
    "rules": {
      "name": [
        "required",
        "max:300"
      ],
      "phone": [
        "required",
        "max:32"
      ],
      "email": [
        "required",
        "email",
        "max:300"
      ],
      "message": [
        "max:2000"
      ],
      "category": [
        "max:32"
      ]
    }
  },
  "CreateCategory": {
    "mutation": CreateCategory,
    "rules": {
      "name": [
        "max:190",
        "required"
      ],
      "slug": [
        "max:190",
        "slugify:name",
        "unique:categories,slug",
        "required"
      ],
      "parent_id": [
        "nullable",
        "exists:categories,id"
      ]
    }
  },
  "UpdateCategory": {
    "mutation": UpdateCategory,
    "rules": {
      "id": [
        "required"
      ],
      "name": [
        "max:190"
      ],
      "slug": [
        "max:190",
        "slugify:name",
        "unique:categories,slug"
      ],
      "seo_title": [
        "nullable"
      ],
      "seo_keywords": [
        "nullable"
      ],
      "seo_description": [
        "nullable"
      ],
      "image_id": [
        "exists:storage,id",
        "nullable"
      ]
    }
  },
  "DeleteCategory": {
    "mutation": DeleteCategory,
    "rules": {
      "id": [
        "required"
      ]
    }
  },
  "CreateCategoryFAQ": {
    "mutation": CreateCategoryFAQ,
    "rules": {
      "category_id": [
        "exists:categories,id"
      ]
    }
  },
  "UpdateCategoryFAQ": {
    "mutation": UpdateCategoryFAQ,
    "rules": {
      "id": [
        "required"
      ]
    }
  },
  "DeleteCategoryFAQ": {
    "mutation": DeleteCategoryFAQ,
    "rules": {
      "id": [
        "required"
      ]
    }
  },
  "DeleteDesignBlockCategory": {
    "mutation": DeleteDesignBlockCategory,
    "rules": {
      "id": [
        "required"
      ]
    }
  },
  "CreateBanner": {
    "mutation": CreateBanner,
    "rules": {}
  },
  "UpdateBanner": {
    "mutation": UpdateBanner,
    "rules": {
      "id": [
        "required"
      ],
      "image_id": [
        "exists:storage,id",
        "nullable"
      ],
      "mobile_image_id": [
        "exists:storage,id",
        "nullable"
      ],
      "link": [
        "link",
        "nullable"
      ]
    }
  },
  "DeleteBanner": {
    "mutation": DeleteBanner,
    "rules": {
      "id": [
        "required"
      ]
    }
  },
  "DeleteDesignBlockBanner": {
    "mutation": DeleteDesignBlockBanner,
    "rules": {
      "id": [
        "required"
      ]
    }
  },
  "CreateDesignBlockCard": {
    "mutation": CreateDesignBlockCard,
    "rules": {
      "design_block_id": [
        "required"
      ]
    }
  },
  "UpdateDesignBlockCard": {
    "mutation": UpdateDesignBlockCard,
    "rules": {
      "id": [
        "required"
      ],
      "title": [
        "nullable"
      ],
      "text": [
        "nullable"
      ],
      "image_id": [
        "exists:storage,id",
        "nullable"
      ]
    }
  },
  "DeleteDesignBlockCard": {
    "mutation": DeleteDesignBlockCard,
    "rules": {
      "id": [
        "required"
      ]
    }
  },
  "CreateDesignBlock": {
    "mutation": CreateDesignBlock,
    "rules": {
      "code": [
        "max:190"
      ]
    }
  },
  "UpdateDesignBlock": {
    "mutation": UpdateDesignBlock,
    "rules": {
      "id": [
        "required"
      ],
      "title": [
        "nullable"
      ],
      "subtitle": [
        "nullable"
      ],
      "min_height": [
        "nullable"
      ],
      "background": [
        "nullable"
      ],
      "var_1": [
        "nullable"
      ],
      "var_2": [
        "nullable"
      ],
      "var_3": [
        "nullable"
      ],
      "var_4": [
        "nullable"
      ],
      "var_5": [
        "nullable"
      ]
    }
  },
  "DeleteDesignBlock": {
    "mutation": DeleteDesignBlock,
    "rules": {
      "id": [
        "required"
      ]
    }
  },
  "UpsertDesignBlockSetting": {
    "mutation": UpsertDesignBlockSetting,
    "rules": {
      "value": [
        "nullable"
      ],
      "image_id": [
        "exists:storage,id",
        "nullable"
      ]
    }
  },
  "OrderNestable": {
    "mutation": OrderNestable,
    "rules": {
      "items": [
        "required"
      ],
      "table": [
        "required"
      ]
    }
  },
  "DeleteDesignBlockProduct": {
    "mutation": DeleteDesignBlockProduct,
    "rules": {
      "id": [
        "required"
      ]
    }
  },
  "CreateProduct": {
    "mutation": CreateProduct,
    "rules": {
      "name": [
        "max:190",
        "required"
      ]
    }
  },
  "UpdateProduct": {
    "mutation": UpdateProduct,
    "rules": {
      "id": [
        "required"
      ],
      "name": [
        "max:190"
      ],
      "image_id": [
        "exists:storage,id",
        "nullable"
      ],
      "seo_title": [
        "nullable"
      ],
      "seo_keywords": [
        "nullable"
      ],
      "seo_description": [
        "nullable"
      ]
    }
  },
  "DeleteProduct": {
    "mutation": DeleteProduct,
    "rules": {
      "id": [
        "required"
      ]
    }
  },
  "UpsertDesignBlockText": {
    "mutation": UpsertDesignBlockText,
    "rules": {
      "text": [
        "nullable"
      ]
    }
  },
  "DeleteDesignBlockText": {
    "mutation": DeleteDesignBlockText,
    "rules": {
      "id": [
        "required"
      ]
    }
  },
  "CreateBlog": {
    "mutation": CreateBlog,
    "rules": {
      "title": [
        "required"
      ]
    }
  },
  "UpdateBlog": {
    "mutation": UpdateBlog,
    "rules": {
      "id": [
        "required"
      ],
      "image_id": [
        "exists:storage,id",
        "nullable"
      ]
    }
  },
  "DeleteBlog": {
    "mutation": DeleteBlog,
    "rules": {
      "id": [
        "required"
      ]
    }
  },
  "CreatePricelist": {
    "mutation": CreatePricelist,
    "rules": {
      "name": [
        "required"
      ],
      "parent_id": [
        "nullable",
        "exists:_pricelist,id"
      ]
    }
  },
  "UpdatePricelist": {
    "mutation": UpdatePricelist,
    "rules": {
      "id": [
        "required"
      ],
      "link": [
        "link",
        "nullable"
      ],
      "price": [
        "nullable",
        "numeric"
      ],
      "discount_price": [
        "nullable",
        "numeric"
      ]
    }
  },
  "DeletePricelist": {
    "mutation": DeletePricelist,
    "rules": {
      "id": [
        "required"
      ]
    }
  },
  "CreateSimptom": {
    "mutation": CreateSimptom,
    "rules": {
      "name": [
        "max:191",
        "required",
        "unique:_simptoms"
      ]
    }
  },
  "UpdateSimptom": {
    "mutation": UpdateSimptom,
    "rules": {
      "id": [
        "required"
      ],
      "name": [
        "max:191"
      ]
    }
  },
  "DeleteSimptom": {
    "mutation": DeleteSimptom,
    "rules": {
      "id": [
        "required"
      ]
    }
  }
}
export default mutations;
