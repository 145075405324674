import {gql} from "@apollo/client";

export const PricelistFragment = gql`
	fragment PricelistFragment on Pricelist {
		id
		parent_id
		position
		name
		link
		action
		price
		discount_price
	}
`

export const GetPricelist = gql`
	${PricelistFragment}
	query ($parent_id: ID, ) {
		GetPricelist (parent_id: $parent_id, ) {
			...PricelistFragment
			children			{
				...PricelistFragment
				children				{
					...PricelistFragment
				}
			}
		}
	}
`

export const CreatePricelist = gql`
	${PricelistFragment}
	mutation ($name: String!, $position: Int, $parent_id: ID, ) {
		CreatePricelist (name: $name, position: $position, parent_id: $parent_id, ) {
			...PricelistFragment
		}
	}
`

export const UpdatePricelist = gql`
	${PricelistFragment}
	mutation ($id: ID!, $name: String, $link: String, $action: Boolean, $price: Float, $discount_price: Float, ) {
		UpdatePricelist (id: $id, name: $name, link: $link, action: $action, price: $price, discount_price: $discount_price, ) {
			...PricelistFragment
		}
	}
`

export const DeletePricelist = gql`
	${PricelistFragment}
	mutation ($id: ID!, ) {
		DeletePricelist (id: $id, ) {
			...PricelistFragment
		}
	}
`

