import {lazy} from "react";
import LangSetLangBoot from "./lang/context/SetLangBoot.js";
import LangTransBoot from "./lang/context/TransBoot.js";
import AlertsAlertPanelBoot from "./alerts/context/AlertPanelBoot.js";
import CmsCheckAdminCookieBoot from "./cms/context/CheckAdminCookieBoot.js";
import CmsCheckAdminRegister from "./cms/context/CheckAdminRegister.js";
import SettingsLoadSettingsRegister from "./settings/context/LoadSettingsRegister.js";
import SettingsSettingRegister from "./settings/context/SettingRegister.js";
import PagesLoadPagesRegister from "./pages/context/LoadPagesRegister.js";
import PagesMenuRegister from "./pages/context/MenuRegister.js";
import PagesRouteRegister from "./pages/context/RouteRegister.js";
import CategoriesLoadCategoriesRegister from "./categories/context/LoadCategoriesRegister.js";
import {authLayoutLoader as cmsAuthLayoutLoader} from "./cms/views/layouts/authLayoutLoader";
import {cmsLayoutLoader as cmsCmsLayoutLoader} from "./cms/views/layouts/cmsLayoutLoader";
import {passwordResetLoader as cmsPasswordResetLoader} from "./cms/views/passwordReset/passwordResetLoader";
import {submitAction as apolloSubmitAction} from "./apollo/providers/submitAction";
import {blocksTemplateLoader as designBlocksTemplateLoader} from "./design/views/blocksTemplate/blocksTemplateLoader";
import {productLoader as productsProductLoader} from "./products/views/product/productLoader";
import AppRouterChangedActions from "./_app/RouterChangedActions";


export const boot=[LangSetLangBoot,LangTransBoot,AlertsAlertPanelBoot,CmsCheckAdminCookieBoot,];
export const register=[CmsCheckAdminRegister,SettingsLoadSettingsRegister,SettingsSettingRegister,PagesLoadPagesRegister,PagesMenuRegister,PagesRouteRegister,CategoriesLoadCategoriesRegister,];

const ReactLazyWithPreload = importStatement => {
  const Component = lazy(importStatement);
  Component.preload = importStatement;
  return Component;
};

export const pages={
  "cms.Administrators": {
    "lazy": ReactLazyWithPreload(() => import(/* webpackChunkName: 'cms.Administrators' */ './cms/views/administrators/Administrators.js'))
  },
  "cms.Dashboard": {
    "lazy": ReactLazyWithPreload(() => import(/* webpackChunkName: 'cms.Dashboard' */ './cms/views/dashboard/Dashboard.js'))
  },
  "cms.Error404": {
    "lazy": ReactLazyWithPreload(() => import(/* webpackChunkName: 'cms.Error404' */ './cms/views/error404/Error404.js'))
  },
  "cms.AuthLayout": {
    "lazy": ReactLazyWithPreload(() => import(/* webpackChunkName: 'cms.AuthLayout' */ './cms/views/layouts/AuthLayout.js')),
    "loader": cmsAuthLayoutLoader
  },
  "cms.CmsLayout": {
    "lazy": ReactLazyWithPreload(() => import(/* webpackChunkName: 'cms.CmsLayout' */ './cms/views/layouts/CmsLayout.js')),
    "loader": cmsCmsLayoutLoader
  },
  "cms.Login": {
    "lazy": ReactLazyWithPreload(() => import(/* webpackChunkName: 'cms.Login' */ './cms/views/login/Login.js')),
    "fallback": "h-80"
  },
  "cms.PasswordEmail": {
    "lazy": ReactLazyWithPreload(() => import(/* webpackChunkName: 'cms.PasswordEmail' */ './cms/views/passwordEmail/PasswordEmail.js')),
    "fallback": "h-80"
  },
  "cms.PasswordReset": {
    "lazy": ReactLazyWithPreload(() => import(/* webpackChunkName: 'cms.PasswordReset' */ './cms/views/passwordReset/PasswordReset.js')),
    "loader": cmsPasswordResetLoader,
    "fallback": "h-80"
  },
  "settings.CmsSettings": {
    "lazy": ReactLazyWithPreload(() => import(/* webpackChunkName: 'settings.CmsSettings' */ './settings/views/cmsSettings/CmsSettings.js'))
  },
  "pages.CmsPages": {
    "lazy": ReactLazyWithPreload(() => import(/* webpackChunkName: 'pages.CmsPages' */ './pages/views/cmsPages/CmsPages.js'))
  },
  "pages.CmsSeoPages": {
    "lazy": ReactLazyWithPreload(() => import(/* webpackChunkName: 'pages.CmsSeoPages' */ './pages/views/cmsSeoPages/CmsSeoPages.js'))
  },
  "storage.CmsStorage": {
    "lazy": ReactLazyWithPreload(() => import(/* webpackChunkName: 'storage.CmsStorage' */ './storage/views/cmsStorage/CmsStorage.js')),
    "action": apolloSubmitAction
  },
  "public.Error404": {
    "lazy": ReactLazyWithPreload(() => import(/* webpackChunkName: 'public.Error404' */ './public/views/Error404/Error404.js'))
  },
  "public.Contact": {
    "lazy": ReactLazyWithPreload(() => import(/* webpackChunkName: 'public.Contact' */ './public/views/contact/Contact.js'))
  },
  "public.PublicLayout": {
    "lazy": ReactLazyWithPreload(() => import(/* webpackChunkName: 'public.PublicLayout' */ './public/views/layouts/PublicLayout.js'))
  },
  "categories.CmsCategories": {
    "lazy": ReactLazyWithPreload(() => import(/* webpackChunkName: 'categories.CmsCategories' */ './categories/views/cmsCategories/CmsCategories.js'))
  },
  "categories.CmsSeoCategories": {
    "lazy": ReactLazyWithPreload(() => import(/* webpackChunkName: 'categories.CmsSeoCategories' */ './categories/views/cmsSeoCategories/CmsSeoCategories.js'))
  },
  "banners.CmsBanners": {
    "lazy": ReactLazyWithPreload(() => import(/* webpackChunkName: 'banners.CmsBanners' */ './banners/views/cmsBanners/CmsBanners.js'))
  },
  "design.BlocksTemplate": {
    "lazy": ReactLazyWithPreload(() => import(/* webpackChunkName: 'design.BlocksTemplate' */ './design/views/blocksTemplate/BlocksTemplate.js')),
    "loader": designBlocksTemplateLoader
  },
  "seo.CmsSeoDefault": {
    "lazy": ReactLazyWithPreload(() => import(/* webpackChunkName: 'seo.CmsSeoDefault' */ './seo/views/cmsSeoDefault/CmsSeoDefault.js'))
  },
  "seo.CmsSeoSitemap": {
    "lazy": ReactLazyWithPreload(() => import(/* webpackChunkName: 'seo.CmsSeoSitemap' */ './seo/views/cmsSeoSitemap/CmsSeoSitemap.js'))
  },
  "products.CmsProducts": {
    "lazy": ReactLazyWithPreload(() => import(/* webpackChunkName: 'products.CmsProducts' */ './products/views/cmsProducts/CmsProducts.js'))
  },
  "products.CmsSeoProducts": {
    "lazy": ReactLazyWithPreload(() => import(/* webpackChunkName: 'products.CmsSeoProducts' */ './products/views/cmsSeoProducts/CmsSeoProducts.js'))
  },
  "products.Product": {
    "lazy": ReactLazyWithPreload(() => import(/* webpackChunkName: 'products.Product' */ './products/views/product/Product.js')),
    "loader": productsProductLoader
  },
  "products.Products": {
    "lazy": ReactLazyWithPreload(() => import(/* webpackChunkName: 'products.Products' */ './products/views/products/Products.js'))
  },
  "blogs.Blog": {
    "lazy": ReactLazyWithPreload(() => import(/* webpackChunkName: 'blogs.Blog' */ './blogs/views/blog/Blog.js'))
  },
  "blogs.Blogs": {
    "lazy": ReactLazyWithPreload(() => import(/* webpackChunkName: 'blogs.Blogs' */ './blogs/views/blogs/Blogs.js'))
  },
  "blogs.CmsBlogs": {
    "lazy": ReactLazyWithPreload(() => import(/* webpackChunkName: 'blogs.CmsBlogs' */ './blogs/views/cmsBlogs/CmsBlogs.js'))
  },
  "pricelist.CmsPricelist": {
    "lazy": ReactLazyWithPreload(() => import(/* webpackChunkName: 'pricelist.CmsPricelist' */ './pricelist/views/cmsPricelist/CmsPricelist.js'))
  },
  "pricelist.Pricelist": {
    "lazy": ReactLazyWithPreload(() => import(/* webpackChunkName: 'pricelist.Pricelist' */ './pricelist/views/pricelist/Pricelist.js'))
  },
  "simptoms.CmsSimptoms": {
    "lazy": ReactLazyWithPreload(() => import(/* webpackChunkName: 'simptoms.CmsSimptoms' */ './simptoms/views/cmsSimptoms/CmsSimptoms.js'))
  },
  "simptoms.Simptom": {
    "lazy": ReactLazyWithPreload(() => import(/* webpackChunkName: 'simptoms.Simptom' */ './simptoms/views/simptom/Simptom.js'))
  },
  "simptoms.Simptoms": {
    "lazy": ReactLazyWithPreload(() => import(/* webpackChunkName: 'simptoms.Simptoms' */ './simptoms/views/simptoms/Simptoms.js'))
  }
}

export const widgets={
  "_app.RouterChangedActions": {
    "import": AppRouterChangedActions
  },
  "lang.EditorWrapper": {
    "lazy": lazy(() => import(/* webpackChunkName: 'lang.EditorWrapper' */ './lang/widgets/EditorWrapper.js'))
  },
  "lang.InputWrapper": {
    "lazy": lazy(() => import(/* webpackChunkName: 'lang.InputWrapper' */ './lang/widgets/InputWrapper.js'))
  },
  "lang.LangSwitcher": {
    "lazy": lazy(() => import(/* webpackChunkName: 'lang.LangSwitcher' */ './lang/widgets/LangSwitcher.js'))
  },
  "lang.StorageWrapper": {
    "lazy": lazy(() => import(/* webpackChunkName: 'lang.StorageWrapper' */ './lang/widgets/StorageWrapper.js'))
  },
  "lang.TextareaWrapper": {
    "lazy": lazy(() => import(/* webpackChunkName: 'lang.TextareaWrapper' */ './lang/widgets/TextareaWrapper.js'))
  },
  "alerts.Alert": {
    "lazy": lazy(() => import(/* webpackChunkName: 'alerts.Alert' */ './alerts/widgets/Alert.js'))
  },
  "storage.CmsStorageDrawer": {
    "lazy": lazy(() => import(/* webpackChunkName: 'storage.CmsStorageDrawer' */ './storage/widgets/CmsStorageDrawer.js'))
  },
  "storage.CmsUpdateStorageDrawer": {
    "lazy": lazy(() => import(/* webpackChunkName: 'storage.CmsUpdateStorageDrawer' */ './storage/widgets/CmsUpdateStorageDrawer.js'))
  },
  "public.Breadcrumbs": {
    "lazy": lazy(() => import(/* webpackChunkName: 'public.Breadcrumbs' */ './public/widgets/Breadcrumbs.js'))
  },
  "public.DarkModeSwitcher": {
    "lazy": lazy(() => import(/* webpackChunkName: 'public.DarkModeSwitcher' */ './public/widgets/DarkModeSwitcher.js'))
  },
  "public.PopupImage": {
    "lazy": lazy(() => import(/* webpackChunkName: 'public.PopupImage' */ './public/widgets/PopupImage.js'))
  },
  "categories.CategoryRender": {
    "lazy": lazy(() => import(/* webpackChunkName: 'categories.CategoryRender' */ './categories/widgets/CategoryRender.js'))
  },
  "categories.CmsAddCategoryProduct": {
    "lazy": lazy(() => import(/* webpackChunkName: 'categories.CmsAddCategoryProduct' */ './categories/widgets/CmsAddCategoryProduct.js'))
  },
  "categories.CmsCategoriesDrawer": {
    "lazy": lazy(() => import(/* webpackChunkName: 'categories.CmsCategoriesDrawer' */ './categories/widgets/CmsCategoriesDrawer.js'))
  },
  "categories.CmsCategoriesFilter": {
    "lazy": lazy(() => import(/* webpackChunkName: 'categories.CmsCategoriesFilter' */ './categories/widgets/CmsCategoriesFilter.js'))
  },
  "categories.CmsDesignBlock": {
    "lazy": lazy(() => import(/* webpackChunkName: 'categories.CmsDesignBlock' */ './categories/widgets/CmsDesignBlock.js'))
  },
  "categories.DesignBlock": {
    "lazy": lazy(() => import(/* webpackChunkName: 'categories.DesignBlock' */ './categories/widgets/DesignBlock.js'))
  },
  "categories.ProductsCategoriesMenu": {
    "lazy": lazy(() => import(/* webpackChunkName: 'categories.ProductsCategoriesMenu' */ './categories/widgets/ProductsCategoriesMenu.js'))
  },
  "banners.CmsDesignBlock": {
    "lazy": lazy(() => import(/* webpackChunkName: 'banners.CmsDesignBlock' */ './banners/widgets/CmsDesignBlock.js'))
  },
  "banners.DesignBlock": {
    "lazy": lazy(() => import(/* webpackChunkName: 'banners.DesignBlock' */ './banners/widgets/DesignBlock.js'))
  },
  "banners.PageBanners": {
    "lazy": lazy(() => import(/* webpackChunkName: 'banners.PageBanners' */ './banners/widgets/PageBanners.js'))
  },
  "design.CmsDesignBlockCards": {
    "lazy": lazy(() => import(/* webpackChunkName: 'design.CmsDesignBlockCards' */ './design/widgets/CmsDesignBlockCards.js'))
  },
  "design.CmsDesignBlockImageText": {
    "lazy": lazy(() => import(/* webpackChunkName: 'design.CmsDesignBlockImageText' */ './design/widgets/CmsDesignBlockImageText.js'))
  },
  "design.CmsDesignBlockInfo": {
    "lazy": lazy(() => import(/* webpackChunkName: 'design.CmsDesignBlockInfo' */ './design/widgets/CmsDesignBlockInfo.js'))
  },
  "design.CmsDesignBlockProgress": {
    "lazy": lazy(() => import(/* webpackChunkName: 'design.CmsDesignBlockProgress' */ './design/widgets/CmsDesignBlockProgress.js'))
  },
  "design.CmsDesignBlockTDL": {
    "lazy": lazy(() => import(/* webpackChunkName: 'design.CmsDesignBlockTDL' */ './design/widgets/CmsDesignBlockTDL.js'))
  },
  "design.CmsDesignBlocks": {
    "lazy": lazy(() => import(/* webpackChunkName: 'design.CmsDesignBlocks' */ './design/widgets/CmsDesignBlocks.js'))
  },
  "design.DesignBlockCards": {
    "lazy": lazy(() => import(/* webpackChunkName: 'design.DesignBlockCards' */ './design/widgets/DesignBlockCards.js'))
  },
  "design.DesignBlockImageText": {
    "lazy": lazy(() => import(/* webpackChunkName: 'design.DesignBlockImageText' */ './design/widgets/DesignBlockImageText.js'))
  },
  "design.DesignBlockInfo": {
    "lazy": lazy(() => import(/* webpackChunkName: 'design.DesignBlockInfo' */ './design/widgets/DesignBlockInfo.js'))
  },
  "design.DesignBlockProgress": {
    "lazy": lazy(() => import(/* webpackChunkName: 'design.DesignBlockProgress' */ './design/widgets/DesignBlockProgress.js'))
  },
  "design.DesignBlockTDL": {
    "lazy": lazy(() => import(/* webpackChunkName: 'design.DesignBlockTDL' */ './design/widgets/DesignBlockTDL.js'))
  },
  "design.DesignBlocksLoadByCode": {
    "lazy": lazy(() => import(/* webpackChunkName: 'design.DesignBlocksLoadByCode' */ './design/widgets/DesignBlocksLoadByCode.js'))
  },
  "design.DesignBlocksRender": {
    "lazy": lazy(() => import(/* webpackChunkName: 'design.DesignBlocksRender' */ './design/widgets/DesignBlocksRender.js'))
  },
  "seo.ProductsSeoCategoryChange": {
    "lazy": lazy(() => import(/* webpackChunkName: 'seo.ProductsSeoCategoryChange' */ './seo/widgets/ProductsSeoCategoryChange.js'))
  },
  "seo.ProductsSeoProductChange": {
    "lazy": lazy(() => import(/* webpackChunkName: 'seo.ProductsSeoProductChange' */ './seo/widgets/ProductsSeoProductChange.js'))
  },
  "products.CmsDesignBlock": {
    "lazy": lazy(() => import(/* webpackChunkName: 'products.CmsDesignBlock' */ './products/widgets/CmsDesignBlock.js'))
  },
  "products.CmsProductDrawer": {
    "lazy": lazy(() => import(/* webpackChunkName: 'products.CmsProductDrawer' */ './products/widgets/CmsProductDrawer.js'))
  },
  "products.CmsProductsDrawer": {
    "lazy": lazy(() => import(/* webpackChunkName: 'products.CmsProductsDrawer' */ './products/widgets/CmsProductsDrawer.js'))
  },
  "products.DesignBlock": {
    "lazy": lazy(() => import(/* webpackChunkName: 'products.DesignBlock' */ './products/widgets/DesignBlock.js'))
  },
  "products.ProductRender": {
    "lazy": lazy(() => import(/* webpackChunkName: 'products.ProductRender' */ './products/widgets/ProductRender.js'))
  },
  "texteditor.TextEditor": {
    "lazy": lazy(() => import(/* webpackChunkName: 'texteditor.TextEditor' */ './texteditor/editor/TextEditor.js'))
  },
  "texteditor.CmsDesignBlock": {
    "lazy": lazy(() => import(/* webpackChunkName: 'texteditor.CmsDesignBlock' */ './texteditor/widgets/CmsDesignBlock.js'))
  },
  "texteditor.DesignBlock": {
    "lazy": lazy(() => import(/* webpackChunkName: 'texteditor.DesignBlock' */ './texteditor/widgets/DesignBlock.js'))
  },
  "blogs.BlogRender": {
    "lazy": lazy(() => import(/* webpackChunkName: 'blogs.BlogRender' */ './blogs/widgets/BlogRender.js'))
  },
  "blogs.CmsDesignBlock": {
    "lazy": lazy(() => import(/* webpackChunkName: 'blogs.CmsDesignBlock' */ './blogs/widgets/CmsDesignBlock.js'))
  },
  "blogs.DesignBlock": {
    "lazy": lazy(() => import(/* webpackChunkName: 'blogs.DesignBlock' */ './blogs/widgets/DesignBlock.js'))
  }
}
